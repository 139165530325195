<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CNavbar light color="light" class="px-0">
          <h4> Student Lists</h4>
          <CForm inline>
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                  ></b-form-input>
              </b-input-group>
              <CButton color="dark" size="sm" class="border-0">
                <CIcon name="cil-search" size="sm" />
              </CButton>&nbsp;&nbsp;
              <CButton
              color="outline-dark "
              class="my-2 my-sm-0 btn-out-dashed"
              type="submit"
              size="sm"
              @click="next_page"
              v-if="user != '8de886dd-12e5-4b02-b812-187639513779'"
              >+ Add New</CButton
              >
          </CForm>
        </CNavbar>
        <div class="table-wrapper py-3">
          <CRow>
            <CCol col="12" xl="12">
              <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="xs"
                  show-empty
                  small
               
                  >
                 <template #cell(action)="data" >
                  <CButton color="primary" size="sm" class="border-0" @click ="get_unique_data(data.item)">
                    View
                  </CButton>
                </template>
              </b-table>
            </CCol>
          </CRow>
          <b-row>
              <b-col sm="2">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
                ></b-pagination>
              </b-col>
              <b-col  offset-sm="7"  sm="3">
                <b-form-group horizontal label="Per page" label-size="sm" :label-cols="6" >
                  <b-form-select :options="pageOptions" v-model="perPage" size="sm"/>
                </b-form-group>
              </b-col>
          </b-row>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AddStudent from "./AddStudent";
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;

export default {
  name: "StudentList",
  data() {
    return {
      items: [],
      filter:null,
      filter_on:[],
      user:"",
      filterOn:[],
      fields: [
        {
          key: "student_name",
          label: "Student Name",
          _classes: "font-weight-bold",
        },
        { key: "student_dob",label: "DOB" },
        { key: "student_address",label: "Address" },
         { key: "parent_name",label: "Parent Name" },
        { key: "parent_email_id",label: "Parent Email" },
        'action'
        
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      activePage: 1,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    get_unique_data(row){
      console.log(row);
      this.$router.push({ name: "profile",params:{id:row,contenttype:'Student'}});
    },
    next_page(){
       this.$router.push({ name: "AddStudent"});
    },
    get_data(){
       var promise = apis.list_Students().then( response => {
          this.items = response.data;
         
      }).catch( errror => {

      })
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
  components:{
    AddStudent
  },
  created(){
    this.get_data();
    this.user = localStorage.getItem('user_role_id');
  }
};
</script>

<style></style>
